import { Component, OnInit } from '@angular/core';
import {Question} from "../../models/question.model";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {Answer} from "../../models/answer.model";
import {QuestionService} from "../../services/question/question.service";
import {SurveyService} from "../../services/survey/survey.service";
import {Router} from "@angular/router";
import {ProgressBarComponent} from "../../components/progress/progress-bar/progress-bar.component";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    NgClass,
    ProgressBarComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit{

  questions?: Array<Question>;
  currentQuestion?: Question | null;
  answers: Array<Answer> = [];
  customizedInputValue: string = '';
  start: boolean = false;
  surveyUniqid: string = '';

  constructor(
    private questionSrv: QuestionService,
    private surveySrv: SurveyService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    await this.questionSrv.getQuestions().subscribe(
      (data) => {
        this.questions = data;
        this.currentQuestion = this.questions.find(question => question.isFirst);
      }
    );
  }

  startSurvey():void {
    this.surveySrv.startSurvey().subscribe(
      (data: string) => {
        this.surveyUniqid = data;
        this.start = true;
      }
    );
  }

  getProgression(): number {
    if (!this.currentQuestion || !this.currentQuestion.position || !this.questions )
    {
      return 0;
    }

    const progress = ( (this.currentQuestion.position + 1) / this.questions.length ) * 100;
    return Math.round(progress);
  }

  next(answer: Answer): void {

    this.answers.push(answer);
    const nextQuestion = this.questions?.find(question => {
      return question.id === answer.linkedQuestion;
    });

    if (nextQuestion) {
      this.currentQuestion = nextQuestion;
      return;
    }

    this.currentQuestion = null;
    this.end();
  }

  previous(): void {
    // redirect to previous question + Delete last answer
    const lastAnswer = this.answers[this.answers.length - 1];
    const previousQuestion = this.questions?.find(question => {
      return question.uniqid === lastAnswer?.question;
    });

    if (previousQuestion)
    {
      this.currentQuestion = previousQuestion;
      this.answers.pop();
    }
  }

  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.customizedInputValue = input.value;
  }

  customizedNext(): void {
    //this.answers.push(new Answer());
    const answer = new Answer();
    answer.customContent = this.customizedInputValue;
    answer.question = this.currentQuestion?.uniqid;
    answer.questionType = this.currentQuestion?.type;
    this.answers.push(answer);

    const nextQuestion = this.questions?.find(question => {
      return question.id === this.currentQuestion?.nextQuestion;
    });

    this.customizedInputValue = '';

    if (nextQuestion) {
      this.currentQuestion = nextQuestion;
      return;
    }

    this.currentQuestion = null;
    this.end();
  }

  end() {
    // Save survey with answers
    this.questionSrv.postAnswers(this.answers, this.surveyUniqid).subscribe(
      (data) => {
        // Stock survey ID in cache + redirect to solutions display page
        this.surveySrv.storeSurvey(data);
        this.router.navigate(['/answers']);
      }
    );
  }
}
