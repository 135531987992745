import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Question} from "../../models/question.model";
import {ToolsService} from "../tools/tools.service";

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(
    private http: HttpClient,
    private toolSrv: ToolsService
  ) { }

  getQuestions(): Observable<Question[]> {
    return this.http.get<Question[]>(`${this.toolSrv.getApiEndpoint()}survey`)
  }

  postAnswers(params: any, uniqid: string): Observable<string> {
    return this.http.post<string>(`${this.toolSrv.getApiEndpoint()}survey/answers/${uniqid}`, params)
  }
}
