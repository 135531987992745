<div class="resume mb-3 pb-5">
  <div class="container resume-content">
    <div class="text-center" *ngIf="error">
      <h3 class="text-primary" translate>Une erreur est survenue.</h3>
      <a href="" class="btn btn-lg btn-info text-white mx-auto mt-3 rounded shadow px-4">Retourner au questionnaire</a>
    </div>
    <ng-container *ngIf="answers">
      <div class="text-center text-primary">
        <span class="material-icons text-info fs-2">check_circle</span>
        <p class="mb-4">Test terminé !</p>
        <h2 class="fs-4 fw-light mb-3">Merci de la confiance que tu nous as accordée en répondant au questionnaire,</h2>
        <h2 *ngIf="!level || level === 0" class="mt-3" translate>Tes réponses nous font penser que tu vis une relation sans violence. Nous en sommes heureux, chacun a droit à une relation apaisée.</h2>
        <p *ngIf="!level || level === 0" class="text-muted mt-3">
          <br>
          <span translate>N'hésite pas à partager ce questionnaire à tes proches qui pourraient en avoir besoin.</span>
          <br>
          <span translate>Tu peux également nous suivre sur les réseaux sociaux.</span>
        </p>
        <h2 *ngIf="level && level === 1" class="mt-3" translate>Les réponses que tu nous a données nous alertent. Nous t'invitons à la vigilance sur les points suivants.</h2>
        <h2 *ngIf="level && level === 2" class="mt-3" translate>Les résultats que tu nous as indiquées nous alertent. Il est très probable que tu sois victime de violences au sein de ton couple.</h2>
        <h3 *ngIf="level && level >= 1 && isAdo" class="mt-3" translate> Nous t'invitons à parler de ta situation à un adulte qui pourra t'aider : tes parents, l'infirmière scolaire, un médecin, un professeur... Il est important que tu ne restes pas seul/e</h3>
        <h3 *ngIf="login" class="mt-3 text-muted fw-bolder" (click)="showLogin()"><span translate>Tu es connecté en tant que : </span>{{login}}</h3>
      </div>

      <div class="row mt-5" *ngIf="thresholds">
        <div class="col-12 col-md-3 text-center">
          <div class="d-flex flex-column mx-auto fit-content">
            <div class="d-flex align-content-center justify-content-start align-items-center mb-3" *ngFor="let threshold of thresholds;">
              <span class="material-icons text-info warning fs-1 me-3" *ngIf="threshold.level === 2">cancel</span>
              <span class="material-icons-outlined text-warning warning fs-1 me-3" *ngIf="threshold.level === 1">warning</span>
              <span class="material-icons-outlined text-success warning fs-1 me-3" *ngIf="threshold.level === 0">check_circle</span>
              {{ threshold.questionGroupName }}
            </div>
          </div>
        </div>
        <div class="col-12 col-md-9">
          <div class="bg-white rounded shadow p-5 resume-text" id="print">
            <div class="threshold" *ngFor="let threshold of thresholds; let last = last">
              <div class="d-flex justify-content-start align-items-center">
                <div class="ms-4 mb-3">
                  <h4 class="text-info">{{ threshold.questionGroupName }}</h4>
                  <p [innerHTML]="getFirstLine(threshold.content)"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-12 col-md-3 text-center text-md-left d-flex justify-content-end flex-column">
          <button class="btn btn-lg btn-white mb-5 mx-auto rounded shadow text-black px-4" (click)="print()">Imprimer</button>
        </div>
        <div class="col-12 col-md-9" *ngIf="!login && answers">
          <div class="my-5 text-center" >
            <p translate>Tu peux créer un compte anonyme afin de sauvegarder tes résultats</p>
            <button class="btn btn-lg btn-info text-white mx-auto mt-3 rounded shadow px-4" (click)="openModalSignup()" translate>Créer un compte</button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="answers || associations">
  <div class="row mt-5 pt-0 pt-md-5" id="details">
    <div class="col-12 col-md-2 sticky-column h-100 mb-3">
      <div class="bg-light shadow rounded-end-5 p-4 text-primary text-center text-md-start">
        <p *ngIf="answers !== undefined && answers !== null && answers.length > 0" class="my-4 pointer-event fs-5 nav-details" (click)="setDiplay('ANSWERS')" [ngClass]="{'text-info': display === 'ANSWERS'}">Analyse juridique</p>
        <p *ngIf="associations !== undefined && associations !== null && associations.length > 0" class="my-4 pointer-event fs-5 nav-details" (click)="setDiplay('ASSOCIATIONS')" [ngClass]="{'text-info': display === 'ASSOCIATIONS'}">Aides d'associations</p>
        <p *ngIf="assistances !== undefined && assistances !== null && assistances.length > 0" class="my-4 pointer-event fs-5 nav-details" (click)="setDiplay('ASSISTANCES')" [ngClass]="{'text-info': display === 'ASSISTANCES'}">Aides de l'État</p>
        <p *ngIf="employers !== undefined && employers !== null && employers.length > 0" class="my-4 pointer-event fs-5 nav-details" (click)="setDiplay('EMPLOYER')" [ngClass]="{'text-info': display === 'EMPLOYER'}">Aides de votre employeur</p>
        <p *ngIf="companies !== undefined && companies !== null && companies.length > 0" class="my-4 pointer-event fs-5 nav-details" (click)="setDiplay('COMPANY')" [ngClass]="{'text-info': display === 'COMPANY'}">Aides des entreprises</p>
      </div>
    </div>
    <div class="col-12 col-md-10">
      <div class="mx-3 mx-md-0 my-3 my-md-0">
        <app-answers-details [answers]="answers" *ngIf="display === 'ANSWERS'"></app-answers-details>
        <app-answers-associations [associations]="associations" *ngIf="display === 'ASSOCIATIONS'"></app-answers-associations>
        <app-answers-assistances [assistances]="assistances" *ngIf="display === 'ASSISTANCES'"></app-answers-assistances>
        <app-answers-company [employers]="employers" *ngIf="display === 'EMPLOYER'"></app-answers-company>
      </div>
    </div>
  </div>
</ng-container>

<div class="py-5 p-md-5 mt-5">
  <div class="mx-4 mx-md-5 px-md-5">
    <h3 class="text-info mb-3">Des informations à savoir</h3>
    <p>
      Comme le mentionne le site arretonslesviolences.gouv.fr du gouvernement français :
    </p>
    <ul>
      <li>Que les faits soient anciens ou récents, IL FAUT EN PARLER à une personne en qui vous avez confiance, à une professionnelle ou un professionnel (médecin, assistante sociale, assistant social, avocate, avocat...) ou à une association spécialisée dans la lutte contre le viol et les violences faites aux femmes qui vous accompagnera.</li>
      <li>Le 3919 est à votre disposition pour vous écouter et vous guider.</li>
    </ul>
    <p><strong class="text-primary">SIGNALEZ</strong> ces faits à la police ou à la gendarmerie. Vous ferez l’objet d’une attention particulière de la part des services de police ou des unités de gendarmerie qui ont mis en place des dispositifs d’accueil et d’aide aux victimes : intervenantes sociales, psychologues, permanence d’association d’aide aux victimes...</p>
    <p>En cas d’urgence, <strong class="text-primary">appelez le 17 ou le 112</strong> (depuis un portable).</p>
  </div>
</div>
<div class="bg-light py-5 p-md-5 mb-3">
  <div class="mx-4 mx-md-5 px-md-5">
    <p><strong class="text-primary">Toutes les femmes</strong> peuvent être confrontées aux violences conjugales, sans distinction d'âge, de condition sociale ou de lieu de résidence.</p>
    <p>Il est important de te rappeler que <strong class="text-primary">tu n'es en aucun cas coupable</strong> des comportements violents de ton partenaire ou ancien partenaire.</p>
    <p><strong class="text-primary">Aucune raison ni excuse</strong> ne peut légitimer des actes d'agression à ton encontre.</p>
    <p> La responsabilité de ces violences repose entièrement sur l'individu qui les commet, et de tels actes sont <strong class="text-primary">sanctionnés par la loi.</strong></p>
  </div>
</div>
