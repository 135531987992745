import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ToolsService} from "../tools/tools.service";
import {Question} from "../../models/question.model";
import {Observable} from "rxjs";
import {AuthService} from "../auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  constructor(
    private http: HttpClient,
    private toolSrv: ToolsService,
    private authSrv: AuthService
  ) { }

  startSurvey(): Observable<string>  {
    return this.http.get<string>(`${this.toolSrv.getApiEndpoint()}survey/start/`)
  }

  storeSurvey(uniqid: string): void {
    sessionStorage.setItem('survey', uniqid);
  }

  getSurvey(): Observable<any> {
    const survey = sessionStorage.getItem('survey');
    return this.http.get<Question[]>(`${this.toolSrv.getApiEndpoint()}survey/answers/${survey || ''}`)
  }
}
